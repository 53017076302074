import React from "react"
import styled from "styled-components"
import {
  Layout,
  SEO,
  Typography,
  GridCell,
  GridMainContent,
  Wrapper,
  Footer,
  Info,
} from "../components"
import AboutUsSvg from "../assets/about-us.svg"
import { breakpoints } from "../styles/breakpoints"

const Text = styled(Typography).attrs({ tag: "p", use: "body1" })`
  margin-bottom: 1.5rem;
`

const AboutPage = () => (
  <Layout page="/about">
    <SEO title="About" />

    <Info
      heading="About us"
      subHeading="Read on to learn more about our founding, our history, our growth, and our ambitions."
      image={
        <AboutUsSvg
          css={`
            width: 80%;
            height: auto;
            margin: auto;
            display: block;

            @media (max-width: ${breakpoints.PHONE}) {
              width: 65%;
            }
          `}
        />
      }
    />

    <Wrapper
      css={`
        max-width: 872px;
      `}
    >
      <GridMainContent>
        <GridCell span={12}>
          <Text>
            Incept5 was originally founded in 2008 by the principals of C24,
            following its sale to Iona Technologies. Intended as an incubator,
            the ‘Incept’ originated from the RUP (Rational Unified Process)
            concept of Inception, and the ‘5’ as an increment to the ‘4’ of
            ‘C24’. (We never claimed expertise in marketing equivalent to that
            of our technology and engineering…)
          </Text>

          <Text>
            Many will remember 2008 as the beginning of the last recession. Our
            early clients, Revolution Money (sold to Amex), Verify (sold to
            VISA) and VISA themselves were perfect examples of our vision and
            ability to apply innovation and modernisation to drive economic
            success for our clients.
          </Text>

          <Text>
            What differentiated us then, and continues to do so, was our team
            and our culture. The team now encompasses the team from 2008, but
            has also grown and broadened. We have stuck together because we
            recognise the value of our team and the power of combining the right
            number of the right kind of people in the right way, at the right
            time. As part of this journey we have grown to offer strength in new
            areas. A decade ago we offered architectural expertise and
            ‘back-end’ Java-on-Linux - from the API down. We’ve expanded our
            teams to include front-end development spanning design & UX through
            to mobile and web UIs. QA is perhaps the hardest thing to get right
            from the start - we now have world-class people in this space,
            specialising in automated testing - and our DevOps team can run
            anything from a RaspberryPi cluster to fault-tolerant, multi-cloud
            setups bridging AWS, GCP and more.
          </Text>

          <Text>
            While we lived and breathed security in the PCI world of VISA, given
            our cryptography credentials it was perhaps inevitable that they
            asked us to evaluate BitCoin in its early days. We have continued to
            expand our coverage of blockchain and are certain we can help
            de-mystify this particular minefield of hype and misinformation if
            you need us to. Speaking of minefields, outside of pure technology,
            we also have a product team who could walk you through ISO 20022
            blindfolded while reciting PSD2 backwards. We are strong believers
            in investing in ourselves. A lot of time our personal time is spent
            on the ‘R’ in R&D - new languages, NFC, secure enclaves, AI, custom
            hardware, Java performance tuning and micro-benchmarking to name a
            few of the areas we’re actively investigating.
          </Text>

          <Text
            css={`
              color: #653edb !important;
            `}
          >
            Oh and if we didn’t mention - we’re not averse to a little partying
            now and again. “Work hard, play hard” was more or less a mission
            statement from the start.
          </Text>
        </GridCell>
      </GridMainContent>
    </Wrapper>

    <Footer />
  </Layout>
)

export default AboutPage
